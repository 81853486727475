import uuid from "uuid/v4";
import React from "react";
import { Link } from "react-router-dom";
import Logo from "../components/Logo";

const Header = () => (
  <div className="flex py-5 items-center border-b-2 border-gray-200 h-24 px-6 text-gray-600">
    <Logo />
    <div className="flex-1" />
    <a
      className="block flex items-center hover:text-gray-700 mr-5"
      href="mailto:fedora.crocus.0z@icloud.com"
    >
      <svg
        className="fill-current w-5 h-5"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>Email</title>
        <path
          d="M20 7.146c0-.667-.333-1.286-.905-1.62L10 1 .905 5.527C.381 5.86 0 6.479 0 7.146v9.092a1.91 1.91 0 001.905 1.905h16.19A1.91 1.91 0 0020 16.238V7.146zm-10 4.568l-7.65-5L10 2.904l7.65 3.81-7.65 5z"
          fillRule="nonzero"
        />
      </svg>
    </a>
  </div>
);

function ProjectInfo() {
  return (
    <div className="flex-1 mr-20">
      <h1 className="text-3xl sm:text-4xl md:text-5xl xl:text-4xl font-light leading-tight">
        A <b>strongly-typed</b>, <b>hosted</b> service for{" "}
        <span className="sm:block text-teal-500 font-normal">
          building JSON configurations endpoint.
        </span>
      </h1>
      <p className="mt-6 leading-relaxed text-lg text-gray-600">
        Zenyatta is a service that will allow you to create OTA update JSON
        service in seconds, also allows your teammates to edit the same JSON at
        any time.
      </p>
      <div className="flex mt-6 justify-start">
        <Link
          to={`/${uuid()}/typescript`}
          className="rounded-lg px-4 py-4 py-3 bg-gray-700 hover:bg-gray-800 text-base text-white font-semibold leading-tight shadow-md"
        >
          Get Started with TypeScript
        </Link>
        <Link
          to={`/${uuid()}/jsonSchema`}
          className="ml-4 rounded-lg px-4 py-4 py-3 text-base bg-white hover:bg-gray-200 text-gray-800 font-semibold leading-tight shadow-md"
        >
          or JSON Schema
        </Link>
      </div>
    </div>
  );
}

function IntroVideo() {
  return (
    <div
      className="flex-1 flex rounded-lg overflow-hidden"
      style={{ height: 360 }}
    ></div>
  );
}

const HomePage = () => {
  return (
    <div className="bg-gray-100">
      <div className="flex flex-col container mx-auto h-screen">
        <Header />
        <div className="flex-1 w-full px-6 pb-20 flex items-center">
          <ProjectInfo />
          <IntroVideo />
        </div>
      </div>
    </div>
  );
};

export default HomePage;
